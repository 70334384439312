var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { useState, useEffect, useRef, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useKeyPress } from 'utils';
var ACTIVE_COLOR = '#9d5c26';
/**
 * Binds mouse events to track a rect based on mouse events within a given canvas element.
 * @param config Configuration and callback for the rect behavior.
 */
export var useRedactions = function (_a) {
    var _b = _a.fill, fill = _b === void 0 ? '#000' : _b, _c = _a.initialRedactions, initialRedactions = _c === void 0 ? [] : _c, _d = _a.externalScale, externalScale = _d === void 0 ? 1 : _d, handleCompletedRect = _a.onComplete, editable = _a.editable;
    var scale = window.devicePixelRatio;
    var minArea = 48 * scale;
    var ref = useRef(null);
    var _e = useState([]), selected = _e[0], setSelected = _e[1];
    var _f = useState([]), hover = _f[0], setHover = _f[1];
    var _g = useState(initialRedactions.map(function (r) { var _a; return (__assign(__assign({}, r), { id: (_a = r.id) !== null && _a !== void 0 ? _a : uuid() })); })), redactions = _g[0], setRedactions = _g[1];
    var _h = useState(false), drawing = _h[0], setDrawing = _h[1];
    var _j = useState(0), x = _j[0], setX = _j[1];
    var _k = useState(0), y = _k[0], setY = _k[1];
    var _l = useState(0), height = _l[0], setHeight = _l[1];
    var _m = useState(0), width = _m[0], setWidth = _m[1];
    var pressedDelete = useKeyPress('Backspace');
    // Delete any redactions on delete.
    useEffect(function () {
        if (pressedDelete && selected.length > 0) {
            setRedactions(selected.reduce(function (p, id) { return p.filter(function (r) { return r.id !== id; }); }, redactions));
            setSelected([]);
        }
    }, [pressedDelete, selected, redactions]);
    var rect = { x: x, y: y, height: height, width: width, fill: fill };
    var getPos = function () {
        var _a, _b;
        var _c = (_b = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect()) !== null && _b !== void 0 ? _b : { x: 0, y: 0 }, x = _c.x, y = _c.y;
        return { left: x + window.scrollX, top: y + window.scrollY };
    };
    /**
     * Translates a pixel dimension via the inverse of the external
     * scale.
     * @param v The value to translate
     */
    var t = function (v) { return (1 / externalScale) * v; };
    var onMouseDown = function (e) {
        if (!editable) {
            return;
        }
        if (hover.length > 0) {
            setSelected(hover);
            return;
        }
        var _a = getPos(), top = _a.top, left = _a.left;
        setDrawing(true);
        setX(t(e.pageX - left));
        setY(t(e.pageY - top));
        setWidth(0);
        setHeight(0);
        setSelected([]);
    };
    var onMouseMove = function (e) {
        if (!editable) {
            return;
        }
        var _a = getPos(), top = _a.top, left = _a.left;
        var px = t(e.pageX - left);
        var py = t(e.pageY - top);
        if (drawing) {
            var w = px - x;
            var h = py - y;
            if (Math.abs(w * h) >= minArea) {
                setWidth(w);
                setHeight(h);
            }
            else {
                setWidth(0);
                setHeight(0);
            }
        }
        else {
            var currentHover = redactions
                .filter(function (r) {
                return px >= r.x &&
                    px <= r.x + r.width &&
                    py >= r.y &&
                    py <= r.y + r.height;
            })
                .map(function (_a) {
                var id = _a.id;
                return id;
            });
            if (hover !== currentHover) {
                setHover(currentHover);
            }
        }
    };
    var onMouseUp = function () {
        if (!editable) {
            return;
        }
        if (drawing) {
            setDrawing(false);
            var w = Math.abs(rect.width);
            var h = Math.abs(rect.height);
            if (Math.abs(w * h) >= minArea) {
                setRedactions(__spreadArrays(redactions, [
                    __assign(__assign({}, rect), { x: rect.width < 0 ? rect.x + rect.width : rect.x, y: rect.height < 0 ? rect.y + rect.height : rect.y, width: w, height: h, id: uuid() }),
                ]));
                setWidth(0);
                setHeight(0);
                if (handleCompletedRect) {
                    handleCompletedRect(rect);
                }
            }
        }
    };
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        var ctx = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getContext('2d');
        if (!ctx) {
            return;
        }
        ctx.clearRect(0, 0, (_c = (_b = ref.current) === null || _b === void 0 ? void 0 : _b.clientWidth) !== null && _c !== void 0 ? _c : 0, (_e = (_d = ref.current) === null || _d === void 0 ? void 0 : _d.clientHeight) !== null && _e !== void 0 ? _e : 0);
        var existingRects = __spreadArrays(redactions, [rect]);
        existingRects.forEach(function (r) {
            ctx.beginPath();
            ctx.fillStyle = rect.fill;
            ctx.fillRect(r.x, r.y, r.width, r.height);
        });
        var rectFromId = function (id) { return redactions.find(function (r) { return r.id === id; }); };
        hover.map(rectFromId).forEach(function (r) {
            if (!r) {
                return;
            }
            ctx.beginPath();
            ctx.rect(r.x, r.y, r.width, r.height);
            ctx.setLineDash([t(1 * scale), t(1 * scale)]);
            ctx.strokeStyle = ACTIVE_COLOR;
            ctx.lineWidth = t(1 * scale);
            ctx.stroke();
        });
        selected.map(rectFromId).forEach(function (r) {
            if (!r) {
                return;
            }
            ctx.beginPath();
            ctx.setLineDash([0, 0]);
            ctx.rect(r.x, r.y, r.width, r.height);
            ctx.strokeStyle = ACTIVE_COLOR;
            ctx.lineWidth = t(2 * scale);
            ctx.stroke();
        });
    }, [ref, rect, redactions, selected]);
    var generateCaptureWithImage = useMemo(function () { return function (img) {
        var _a, _b;
        var ctx = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getContext('2d');
        if (!ctx) {
            return;
        }
        ctx.drawImage(img, 0, 0);
        ctx.drawImage(img, 0, 0, img.width, img.height);
        redactions.forEach(function (r) {
            ctx.beginPath();
            ctx.fillStyle = rect.fill;
            ctx.fillRect(r.x, r.y, r.width, r.height);
        });
        return (_b = ref.current) === null || _b === void 0 ? void 0 : _b.toDataURL('image/jpeg', 0.7);
    }; }, [ref, redactions]);
    var reset = function () {
        setRedactions([]);
    };
    var domEventHandlers = {
        onMouseUp: onMouseUp,
        onMouseMove: onMouseMove,
        onMouseDown: onMouseDown,
    };
    return {
        ref: ref,
        rect: rect,
        reset: reset,
        domEventHandlers: domEventHandlers,
        generateCaptureWithImage: generateCaptureWithImage,
    };
};
