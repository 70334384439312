import React from 'react';
import { oftmcssSrc } from '../svg/';
export var HSPortalLogo = function () { return (React.createElement("div", { className: "flex font-body" },
    React.createElement("img", { src: oftmcssSrc, alt: "", style: { width: '8rem' }, className: "h-auto my-auto mr-2" }),
    React.createElement("h1", { className: "flex flex-col p-0 m-0 font-extrabold leading-none text-lg" },
        React.createElement("span", { className: "text-accentlogo" }, "Yavapai"),
        ' ',
        React.createElement("span", { className: "text-accentlogo" }, "Homeschool Connect")))); };
export var HSPortalAdminLogo = function () { return (React.createElement("div", { className: "flex flex-row font-body " },
    React.createElement("h2", { className: "text-white text-3xl ml-3 my-auto font-bold uppercase mr-1" }, "Admin"),
    React.createElement("h1", { className: "flex flex-col p-0 m-0 font-bold leading-none text-xs text-left my-auto" },
        React.createElement("span", { className: "text-white" }, "Homeschool"),
        React.createElement("span", { className: "text-white" }, "Connect")))); };
