import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { concatStyles } from 'utils';
var CONTAINER_STYLES = 'flex flex-col bg-accent rounded relative';
var DISMISS_BTN_STYLES = 'text-body absolute top-0 right-0 text-xl -mt-4 -mr-4 bg-transparent border-none ';
export var Dismissable = function (_a) {
    var handleDismiss = _a.onDismiss, children = _a.children, className = _a.className, 
    /**
     * Optional prop to override the default padding
     */
    _b = _a.paddingClassName, 
    /**
     * Optional prop to override the default padding
     */
    paddingClassName = _b === void 0 ? 'p-4' : _b;
    return (React.createElement("div", { className: concatStyles([CONTAINER_STYLES, className, paddingClassName]) },
        children,
        handleDismiss ? (React.createElement("button", { className: DISMISS_BTN_STYLES, onClick: handleDismiss },
            React.createElement("span", { className: "p-2 " },
                React.createElement(FontAwesomeIcon, { icon: faTimesCircle })))) : null));
};
