var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { concatStyles } from 'utils';
var BASE_STYLES = 'flex relative rounded';
var CARD_STYLES = 'bg-white shadow';
var INTERNAL_CARD_STYLES = "bg-studentcard";
var CARD_HOVER_STYLES = 'hover:shadow-md transition-shadow duration-200';
var CARD_DISABLED_STYLES = 'opacity-50 pointer-none';
var CARD_BORDER_STYLES = 'border border-contentAreaBackgroundAlt';
var HIDDEN_BUTTON_STYLES = 'absolute top-0 left-0 h-full w-full opacity-0 cursor-pointer z-10';
var DEFAULT_PADDING = 'p-2';
var SHADOW_STYLES = 'shadow';
export var View = function (_a) {
    var _b;
    var children = _a.children, onClick = _a.onClick, accessibilityLabel = _a.accessibilityLabel, onMouseOut = _a.onMouseOut, onMouseOver = _a.onMouseOver, p = __rest(_a, ["children", "onClick", "accessibilityLabel", "onMouseOut", "onMouseOver"]);
    return (React.createElement("div", { className: concatStyles([
            BASE_STYLES,
            !p.internal && !p.invisible ? CARD_STYLES : '',
            p.internal ? INTERNAL_CARD_STYLES : '',
            p.outline ? CARD_BORDER_STYLES : '',
            p.disabled ? CARD_DISABLED_STYLES : '',
            p.hover && !p.disabled ? CARD_HOVER_STYLES : '',
            (_b = p.className) !== null && _b !== void 0 ? _b : '',
            p.shadow ? SHADOW_STYLES : '',
            DEFAULT_PADDING,
        ]), style: p.style, onMouseOver: onMouseOver, onMouseOut: onMouseOut },
        children,
        onClick && !p.disabled ? (React.createElement("button", { className: HIDDEN_BUTTON_STYLES, onClick: onClick }, accessibilityLabel !== null && accessibilityLabel !== void 0 ? accessibilityLabel : 'click to continue')) : null));
};
View.displayName = 'Card.View';
